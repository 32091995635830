@import "./variables.scss";

@mixin noBtnMotion {
  &:before {
    display: none;
  }
  .v-ripple__container {
    display: none !important;
  }
}

@mixin transitionSet {
  transform: perspective(500px) rotate(0.001deg);
}

@mixin breakpoint($size) {
  @if $size == minMobile {
    @media only screen and (max-width:540px) {
      @content;
    }
  }
  @else if $size == mobile {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @else if $size == noneTuchDevice {
    @media only screen and (min-width:960px) {
      @content;
    }
  }
  @else if $size == tablet {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
  @else if $size == ready {
    @media only screen and (max-width: 1700px) {
      @content;
    }
  }
}

@mixin setIcon($width, $height, $file, $color) {
  @include size($width, $height);
  mask-image: url($G-path-icon + '#{$file}.svg');
  -webkit-mask-image: url($G-path-icon + '#{$file}.svg');
  background-color: $color;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin text($size) {
  @if $size == 24 {
    @include string(rem(24), rem(24), rem(-0.96), 500, left, auto);
    @content;
  }
  @else if $size == 21 {
    @include string(rem(21), rem(21), rem(-0.84), 500, left);
    @content;
  }
  @else if $size == 16 {
    @include string(rem(16), rem(16), rem(-0.64), 500, left, auto);
    @include breakpoint(mobile) {
      @include string(14px, 14px, -0.56px, 500, left, auto)
    }
    @content;
  }
}
@mixin px($size) {
  @if $size == 11 {
    @include string(11px, 11px, -0.44px, 500, left, auto);
    @content;
  }
  @if $size == 12 {
    @include string(12px, 12px, -0.48px, 500, left, auto);
    @content;
  }
  @else if $size == 14 {
    @include string(14px, 14px, -0.56px, 500, left, auto);
    @content;
  }
  @else if $size == 16 {
    @include string(16px, 16px, -0.64px, 500, left, auto);
    @content;
  }
  @else if $size == 18 {
    @include string(18px, 18px, -0.72px, 500, left, auto);
    @content;
  }
  @else if $size == 21 {
    @include string(21px, 21px, -0.84px, 500, left, auto);
    @content;
  }
  @else if $size == 24 {
    @include string(24px, 24px, -0.96px, 500, left, auto);
    @content;
  }
}

@mixin disableDrag() {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

@mixin string(
  $size,
  $height,
  $spacing,
  $weight: normal,
  $align: left,
  $color: var(--text-primary)
) {
  font-family: 'Pretendard';
  font-size: $size;
  line-height: $height;
  letter-spacing: $spacing;
  font-weight: $weight;
  text-align: $align;
  color: $color;
}

@mixin size($width, $height: auto) {
  width: $width;
  height: $height;
  @content;
}

@mixin same($size) {
  width: $size;
  height: $size;
  @content;
}

@mixin flex($content: center, $align: center, $col: column) {
  display: flex;
  justify-content: $content;
  align-items: $align;
  flex-direction: $col;
}

@mixin updateHeader {
  @include flex(flex-start, flex-start, row);
  gap: 5.5px;
  &::after {
    @include same(6px);
    content: '';
    display: block;
    background-color: #ff5c72;
    border-radius: 50%;
  }
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin iconSet {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin scrollCustom($color: #fff) {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $color;
  }
}

@mixin scrollCustomSmall($color: #fff) {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $color;
  }
}


@-webkit-keyframes fadeInUp{
  0%{transform:translate(0px, 20px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}
@-moz-keyframes fadeInUp{
  0%{transform:translate(0px, 20px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}
@keyframes fadeInUp{
  0%{transform:translate(0px, 20px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}
@-webkit-keyframes fadeInUp2{
  0%{transform:translate(0px, 20px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}
@-moz-keyframes fadeInUp2{
  0%{transform:translate(0px, 20px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}
@keyframes fadeInUp2{
  0%{transform:translate(0px, 20px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}
@-webkit-keyframes fadeIn{
  0%{opacity: 0;}
  100%{opacity: 1;}
}
@-moz-keyframes fadeIn{
  0%{opacity: 0;}
  100%{opacity: 1;}
}
@keyframes fadeIn{
  0%{opacity: 0;}
  100%{opacity: 1;}
}