* {
  font-family: 'Pretendard';
  -webkit-tap-highlight-color: transparent;

  img {
    -webkit-user-drag: none;
  }
}

:root {
  font-size: 18px;
  @include breakpoint(tablet) {
    font-size: 16px;
  }
  @include breakpoint(mobile) {
    font-size: 14px;
  }

  --G-max-width: 1128px;
  --G-gnbside-width: 13.77rem;
  --G-gnbside-small-width: 49px;
  --G-optionside-width: 20rem;
  --G-header-hegiht: 3.61rem;
  --G-header-hegiht-m: 90px;
  --G-navigation-hegiht-m: 56px;

  --G-aside-content-width: 936px;

  --box-radius: 8px;

  // background
  --background-default: rgba(22, 22, 22, 1);
  --background-active: rgba(141, 141, 141, 0.4);
  --background-hover: rgba(141, 141, 141, 0.16);
  --background-selected: rgba(141, 141, 141, 0.24);
  --background-selected-hover: rgba(141, 141, 141, 0.32);
  --background-brand: rgba(254, 15, 101, 1); // FIXME:
  --background-inverse: rgba(244, 244, 244, 1);
  --background-inverse-hover: rgba(229, 229, 229, 1);

  // layer
  --layer-01: rgba(38, 38, 38, 1);
  --layer-02: rgba(57, 57, 57, 1);
  --layer-03: rgba(82, 82, 82, 1);
  --layer-hover-01: rgba(51, 51, 51, 1);
  --layer-hover-02: rgba(71, 71, 71, 1);
  --layer-hover-03: rgba(99, 99, 99, 1);
  --layer-active-01: rgba(82, 82, 82, 1);
  --layer-active-02: rgba(111, 111, 111, 1);
  --layer-active-03: rgba(141, 141, 141, 1);
  --layer-selected-01: rgba(57, 57, 57, 1);
  --layer-selected-02: rgba(82, 82, 82, 1);
  --layer-selected-03: rgba(111, 111, 111, 1);
  --layer-selected-hover-01: rgba(76, 76, 76, 1);
  --layer-selected-hover-02: rgba(101, 101, 101, 1);
  --layer-selected-hover-03: rgba(94, 94, 94, 1);
  --layer-disabled: rgba(111, 111, 111, 1);

  // text
  --text-primary: rgba(244, 244, 244, 1);
  --text-secondary: rgba(198, 198, 198, 1);
  --text-placeholder: rgba(111, 111, 111, 1);
  --text-on-color: rgba(255, 255, 255, 1);
  --text-helper: rgba(141, 141, 141, 1);
  --text-error: rgba(255, 131, 137, 1);
  --text-inverse: rgba(22, 22, 22, 1);
  --text-disabled: rgba(77, 77, 77, 1);
  --text-on-color-disabled: rgba(244, 244, 244, 0.25);

  // link
  --link-primary: #FFD56A;
  // --link-primary-hover: rgba(166, 200, 255, 1);
  --link-secondary: rgba(166, 200, 255, 1);
  --link-inverse: rgba(15, 98, 254, 1);
  --link-visited: rgba(190, 149, 255, 1);

  // border
  --border-subtle-00: rgba(57, 57, 57, 1);
  --border-subtle-01: rgba(57, 57, 57, 1);
  --border-subtle-02: rgba(82, 82, 82, 1);
  --border-subtle-03: rgba(111, 111, 111, 1);
  --border-subtle-selected-01: rgba(111, 111, 111, 1);
  --border-subtle-selected-02: rgba(111, 111, 111, 1);
  --border-subtle-selected-03: rgba(141, 141, 141, 1);
  --border-strong-01: rgba(111, 111, 111, 1);
  --border-strong-02: rgba(141, 141, 141, 1);
  --border-strong-03: rgba(168, 168, 168, 1);
  --border-inverse: rgba(244, 244, 244, 1);
  --border-inverse-02: rgba(198, 198, 198, 1);
  --border-interactive: rgba(69, 137, 255, 1);
  --border-disabled: rgba(81, 81, 81, 1);
  --button-disabled: rgba(82, 82, 82, 1);
  --border-tile-01: rgba(82, 82, 82, 1);
  --border-tile-02: rgba(111, 111, 111, 1);
  --border-tile-03: rgba(141, 141, 141, 1);

  // icon
  --icon-primary: rgba(244, 244, 244, 1);
  --icon-secondary: rgba(198, 198, 198, 1);
  --icon-on-color: rgba(255, 255, 255, 1);
  --icon-inverse: rgba(22, 22, 22, 1);
  --icon-on-color-disabled: rgba(244, 244, 244, 0.25);
  --icon-disabled: rgba(244, 244, 244, 0.25);
  --icon-interactive: rgba(255, 255, 255, 1);

  // field
  --field-01: rgba(38, 38, 38, 1);
  --field-02: rgba(57, 57, 57, 1);
  --field-03: rgba(82, 82, 82, 1);
  --field-hover-01: rgba(51, 51, 51, 1);
  --field-hover-02: rgba(71, 71, 71, 1);
  --field-hover-03: rgba(99, 99, 99, 1);
  --field-inverse: rgba(244, 244, 244, 1);

  // support
  --support-error: rgba(250, 77, 86, 1);
  --support-success: rgba(66, 190, 101, 1);
  --support-warning: rgba(241, 194, 27, 1);
  --support-info: rgba(69, 137, 255, 1);
  --support-error-inverse: rgba(218, 30, 40, 1);
  --support-success-inverse: rgba(36, 161, 72, 1);
  --support-warning-inverse: rgba(241, 194, 27, 1);
  --support-info-inverse: rgba(0, 67, 206, 1);
  --support-caution-major: rgba(255, 131, 43, 1);
  --support-caution-minor: rgba(241, 194, 27, 1);
  --support-undefined: rgba(165, 110, 255, 1);
  --support-error-border: rgba(218, 30, 40, 0.3);
  --support-success-broder: rgba(36, 161, 72, 0.3);
  --support-warning-border: rgba(241, 194, 27, 0.3);
  --support-info-border: rgba(69, 137, 255, 0.3);

  // focus
  --focus-default: rgba(255, 255, 255, 1);
  --focus-inset: rgba(22, 22, 22, 1);
  --focus-inverse: rgba(15, 98, 254, 1);

  //notification
  --focus-notification-error-background: rgba(38, 38, 38, 1);
  --focus-notification-success-background: rgba(38, 38, 38, 1);
  --focus-notification-warning-background: rgba(38, 38, 38, 1);
  --focus-notification-info-background: rgba(38, 38, 38, 1);
  --focus-notification-action-hover: rgba(255, 255, 255, 1);
  --focus-notification-action-tertiary-inverse: rgba(15, 98, 254, 1);
  --focus-notification-action-tertiary-inverse-text: rgba(255, 255, 255, 1);
  --focus-notification-action-tertiary-inverse-hover: rgba(3, 83, 233, 1);
}

body {
  overflow: hidden;
  font-size: calc(50 / 16) * 1px;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: var(--background-default);
  color: var(--text-primary);
  @include breakpoint(mobile) {
    height: auto;
  }
}

html {
  overflow-x: hidden;
  font-size: 1rem;
  @include breakpoint(mobile) {
    overflow-y: scroll;
  }
}

span,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  transform: skew(-0.1deg);
}
// textarea,
// input,
// select

a,
textarea,
input,
select,
button {
  font-size: 18px;
  @include breakpoint(tablet) {
    font-size: 16px;
  }
  @include breakpoint(mobile) {
    font-size: 14px;
  }
}

textarea,
input,
select {
  outline: none;
  border: none;
  background: none;
}

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: var(--text-primary) !important;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select {
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}
