.game-card-item {
  cursor: pointer;
  aspect-ratio: 1/1;
  .hover-wrapper {
    display: none;
  }
  .thumbnail {
    @include same(100%);
    object-fit: contain;
    position: relative;
    img {
      @include same(100%);
      object-fit: contain;
      position: absolute;
      z-index: 1;
      border-radius: var(--box-radius);
      overflow: hidden;
    }
    .MuiSkeleton-root {
      @include same(100%);
      position: absolute;
      border-radius: var(--box-radius);
      overflow: hidden;
    }
    .tag {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      border-top-left-radius: 7.1px;
      overflow: hidden;
      &.datily-wins {
        @include same(70px);
        object-fit: contain;
        aspect-ratio: 1/1;
        background: url($G-path-tag + '200x200_Daily_Wins_Tag_EN.png') $G-img-contain;
      }
    }
  }
  @include breakpoint(noneTuchDevice) {
    position: relative;
    transition: transform 0.3s ease-out;
    .hover-wrapper {
      @include same(100%);
      @include flex;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      content: '';
      z-index: 100;
      display: block;
      background: linear-gradient(180deg, #FFB800 0%, #8A6400 100%);
      transition: opacity 0.3s ease-out;
      border-radius: var(--box-radius);
      overflow: hidden;
      &__inner {
        @include flex;
        height: 100%;
        .hover-item {
          @include flex(space-between, center);
          height: calc(100% - 40px);
          padding: 24px 16px 16px;
          .game-name {
            @include string(14px, 18px, normal, 600, center, var(--text-primary));
          }
          .game-type {
            @include string(12px, 16px, 0.32px, 400, center, var(--text-primary));
          }
        }
      }
    }
    &.has:hover {
      transform: translateY(-8px);
      transition: transform 0.3s ease-out;
      .hover-wrapper {
        opacity: 0.95;
        transition: opacity 0.3s 0.2s ease-out;
      }
    }
  }
}

.game-wrapper {
  // -webkit-animation: fadeInUp ease-in 0.25s;
  // animation: fadeInUp ease-in 0.25s;
  // -webkit-animation-fill-mode: forwards;
  // animation-fill-mode: forwards;
  // -webkit-animation-duration: 0.3s;
  // animation-duration: 0.3s;
  .filter-wrapper {
    margin-top: 16px;
    display: grid;
    grid-template-columns: auto 264px;
    gap: 24px;
    align-items: center;
    @include breakpoint(mobile) {
      grid-auto-rows: auto;
      grid-template-columns: 1fr;
      gap: 16px;
    }
    .MuiInputBase-root.base-select {
      height: 100%;
      // @include breakpoint(mobile) {
      //   width: 100%;
      // }
    }
  }
  .game-inner {
    margin-top: 24px;
    min-height: 50vh;
    position: relative;
    .game-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
      gap: 24px;
      @include breakpoint(mobile) {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      }
    }
    .not-list {
      @include flex;
      min-height: 60vh;
      p {
        @include string(14px, 18px, normal, 400, center, var(--text-primary));
      }
    }
    .more-wrapper {
      @include flex(flex-start);
      cursor: pointer;
      width: 100%;
      height: 10vh;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #161616;
      transform: translateY(100%);
      z-index: 110;
      &::before {
        content: '';
        display: block;
        height: 20vh;
        width: 100%;
        background: linear-gradient(180deg, rgba(22, 22, 22, 0.00) 0%, #161616 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(-50%);
        z-index: 110;
      }
      .more-btn {
        @include flex(flex-start, center, row);
        @include string(14px, 18px, normal, 500, right, var(--text-primary));
        margin: 27px 0;
        &::after {
          @include same(24px);
          content: '';
          display: block;
          background: url($G-path-icon + 'more.svg') $G-img-contain;
        }
        &.open::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.m-gameplay-wrapper {
  @include flex;
  // min-height: calc(100vh - 56px);
  min-height: 70vh;
  .game-fullpage-close-btn {
    @include same(32px);
    background: url($G-path-icon + 'game-fullpage-close.svg') $G-img-contain;
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 1010;
    transition: background 0.2s ease-out;
  }
  .game-stack {
    @include flex;
    border-radius: var(--box-radius);
    background: var(--layer-02);
    padding: 16px;
    width: calc(100% - 64px);
    &__not {
      margin-bottom: 13px;
      p {
        @include string(16px, 20px, normal, 500, left, var(--text-primary));
      }
    }
    &__bonus {
      @include flex;
      padding: 0 12px 20px;
      p {
        @include string(16px, 20px, normal, 500, left, var(--text-primary));
        margin-bottom: 4px;
      }
      span {
        @include string(12px, 16px, 0.32px, 400, center, var(--text-primary));
      }
    }
    &__head {
      width: 100%;
      display: grid;
      grid-auto-rows: auto;
      gap: 6px;
      margin-bottom: 13px;
      // padding: 0 16px;
      p {
        @include string(16px, 20px, normal, 500, left, var(--text-primary));
      }
    }
    &__body {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      align-items: center;
      &.solo {
        grid-template-columns: 1fr;
      }
    }
  }

}

.gameplay-wrapper {
  margin: rem(80) 0 rem(150);
  min-height: 100%;
  @include breakpoint(mobile) {
    margin: 50px 0 0;
    padding: 0 0 120px;
  }
  .game-header {
    @include flex(space-between, center, row);
    margin-bottom: 9px;
    &__title {
      @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
      @include breakpoint(mobile) {
        @include string(16px, 20px, normal, 500, left, var(--text-primary));
      }
    }
    &__action {
      @include flex(flex-end, center, row);
      gap: 16px;
      .fullpage-btn {
        @include same(20px);
        background: url($G-path-icon + 'game-fullpage.svg') $G-img-contain;
        &:disabled {
          opacity: 0.5;
        }
      }
      .close-btn {
        @include same(20px);
        background: url($G-path-icon + 'game-close.svg') $G-img-contain;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .game-fullpage-close-btn {
    @include same(32px);
    background: url($G-path-icon + 'game-fullpage-close.svg') $G-img-contain;
    position: fixed;
    top: 24px;
    right: 24px;
    z-index: 1010;
    transition: background 0.2s ease-out;
  }
  .game-container {
    height: 100%;
    max-height: unset;
    position: relative;
    overflow: hidden;
    @include breakpoint(mobile) {
      height: 600px;
    }
    .loading {
      @include flex;
      @include same(100%);
      position: absolute;
      display: grid;
      background: #0000008a;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 20;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 56.25%;
    }
    .frame-game {
      @include same(100%);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      &.fullscreen {
        position: fixed;
        bottom: auto;
        right: auto;
        z-index: 1000;
      }
    }
    // aspect-ratio: auto 709 / 936;
    .overlay {
      @include flex;
      @include same(100%);
      position: absolute;
      background: #0000008a;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      backdrop-filter: blur(10px);

      .game-stack {
        @include flex;
        border-radius: var(--box-radius);
        background: var(--layer-02);
        padding: 16px;
        width: 360px;
        @include breakpoint(mobile) {
          width: calc(100% - 64px);
        }
        &.hide {
          display: none;
        }
        &__not {
          margin-bottom: 13px;
          p {
            @include string(16px, 20px, normal, 500, left, var(--text-primary));
          }
        }
        &__bonus {
          @include flex;
          padding: 0 24px 20px;
          @include breakpoint(mobile) {
            padding: 0 12px 20px;
          }
          p {
            @include string(16px, 20px, normal, 500, left, var(--text-primary));
            margin-bottom: 4px;
          }
          span {
            @include string(12px, 16px, 0.32px, 400, center, var(--text-primary));
          }
        }
        &__head {
          display: grid;
          grid-template-columns: auto minmax(168px, auto);
          gap: 16px;
          align-items: center;
          margin-bottom: 13px;
          padding: 0 16px;
          min-height: 42px;
          p {
            @include string(16px, 20px, normal, 500, left, var(--text-primary));
          }
          @include breakpoint(mobile) {
            grid-auto-rows: auto;
            grid-template-columns: 1fr;
            gap: 6px;
            padding: 0;
          }
        }
        &__body {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          align-items: center;
          &.solo {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
  .game-footer-content {
    margin-top: rem(50);
  }
}