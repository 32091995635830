.affiliate-wrapper {
  .affiliate-inner {
    min-height: 50vh;
    .overview-content {
      background-color: var(--layer-01);
      border-radius: var(--box-radius);
      padding: 24px;
      @include breakpoint(mobile) {
        padding: 32px 24px 24px;
      }

      &__title {
        @include string(16px, 20px, normal, 600, left, var(--text-primary));
      }
      &__inner {
        margin-top: 24px;
        p {
          @include string(16px, 22px, normal, 400, left, var(--text-secondary));
          .primary-mail {
            @include string(16px, 22px, normal, 300, left, auto);
            text-decoration: underline;
            color: #FFD56A !important;
          }
        }
        .commission {
          &__user {
            &--box {
              @include flex;
              background-color: var(--layer-02);
              border-radius: var(--box-radius);
              border: 1px solid #FFCA43;
              margin: 8px 0;
              padding: 4px 16px;
              width: calc(240px - 32px);
              p {
                @include string(32px, 40px, normal, 700, left, #F1C21B);
              }
            }
          }
        }
        .subText {
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
          &__desc {
            p {
              @include string(16px, 22px, normal, 300, left, auto);
              &:first-of-type {
                margin-bottom: 5px;
                color: var(--text-primary);
              }
            }
          }
        }
        .list {
          margin: 24px 0;
          li {
            display: grid;
            grid-template-columns: 6px auto;
            gap: 8px;
            @include breakpoint(mobile) {
              grid-template-columns: 2px auto;
            }
            &:not(:last-of-type) {
              margin-bottom: 8px;
            }
            &::before {
              @include same(6px);
              content: '';
              display: block;
              background-color: var(--text-secondary);
              border-radius: 50%;
              transform: translateY(7px);
              @include breakpoint(mobile) {
                @include same(2px);
                transform: translateY(10px);
              }
            }
          }
        }
        .referral-wrapper {
          margin: 43px 0 16px;
          .copy-item-wrapper {
            background-color: var(--layer-02);
            padding: 14px;
            border-radius: var(--box-radius);
            margin-top: 6px;
            max-width: rem(466);
            @include breakpoint(mobile) {
              max-width: 100%;
            }
            p {
              color: var(--text-primary);
              @include breakpoint(mobile) {
                @include string(12px, 16px, 0.32px, 400, left, var(--text-primary));
              }
            }
          }
        }
        .link-banner-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: rem(24);
          align-items: center;
          @include breakpoint(mobile) {
            gap: 16px;
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
          }
          &__image {
            @include flex(center, flex-start);
            aspect-ratio: 432/142;
            cursor: pointer;
            border-radius: var(--box-radius);
            &.guide {
              background: url($G-path-content + 'banner/affilatesGuide.png') $G-img-cover;
            }
            &.commission {
              background: url($G-path-content + 'banner/commission.png') $G-img-cover;
            }
            p {
              @include string(rem(24), rem(32), normal, 700, left, var(--text-primary));
              padding: 0 rem(24);
              width: 60%;
              @include breakpoint(mobile) {
                @include string(20px, 28px, normal, 700, left, var(--text-primary));
                padding: 0 16px;
              }
            }
          }
        }
      }
    }
    .campaign-content {
      width: 100%;
      overflow: hidden;
      &__title {
        @include flex(center, center, row);
        gap: 30px;
        padding: rem(14) rem(19);
        background-color: var(--layer-01);
        border-radius: var(--box-radius);
        margin-bottom: 24px;
        @include breakpoint(mobile) {
          flex-direction: column;
          gap: 0;
          padding: 15px 20px;
        }
        .info {
          @include string(rem(16), rem(22), normal, 400, left, var(--text-primary));
          .break {
            display: none;
          }
          @include breakpoint(mobile) {
            @include string(12px, 18px, 0.32px, 400, center, var(--text-primary));
            margin-bottom: 12px;
            .break {
              display: block;
            }
          }
          span {
            text-decoration: underline;
          }
        }
      }
      &__head {
        display: grid;
        grid-template-columns: 1fr 1px 2fr;
        padding: rem(14) rem(19);
        background-color: var(--layer-01);
        border-radius: var(--box-radius);
        @include breakpoint(mobile) {
          grid-template-columns: 1fr;
          grid-auto-rows: auto;
          padding: 14px 19px 32px;
        }
        .line {
          @include size(1px, 71px);
          background-color: #393939;
          @include breakpoint(mobile) {
            display: none;
          }
        }
        .head-title {
          @include string(14px, 18px, normal, 500, left, var(--text-helper));
        }
        .total-reward {
          display: grid;
          grid-template-columns: 86px repeat(2, auto);
          align-items: center;
          margin-right: rem(20);
          @include breakpoint(mobile) {
            margin: 0 0 24px;
            padding: 0 0 14px;
            border-bottom: 1px solid #393939;
            gap: 16px;
          }
          &::before {
            @include same(100%);
            max-width: 86px;
            max-height: 75px;
            aspect-ratio: 86/75;
            object-fit: contain;
            content: '';
            display: block;
            background: url($G-path-icon + 'manage-campaign.png') $G-img-contain;
          }
          &__inner {
            margin-left: rem(17);
            .total-reward-data {
              margin-top: 4px;
              @include string(24px, 32px, normal, 700, left, #FFB800);
            }
          }
        }
        .head-inner {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          padding-right: rem(20);
          @include breakpoint(mobile) {
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
            padding: 0;
            gap: 24px;
          }
          li {
            @include flex;
            .head-data {
              @include string(20px, 28px, normal, 700, left, var(--text-primary));
              margin-top: 6px;
            }
          }
        }
      }
      &__body {
        @include flex(flex-start, flex-end);
        // margin-top: rem(32);
        @include breakpoint(mobile) {
          align-items: center;
          margin-top: 24px;
        }
        .accordion-table-container {
          @include scrollCustom;
          margin-bottom: 24px;
          overflow-x: auto;
          width: 100%;
          &__inner {
            min-width: 936px;
            @include breakpoint(mobile) {
              min-width: auto;
            }
            .no-list {
              position: relative;
              min-height: 20vh;
              img {
                @include size(rem(189), rem(29));
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include breakpoint(mobile) {
                  @include size(rem(189, 23), rem(29, 23));
                }
              }
            }
          }
          &__head {
            li {
              &.name, &.commission, &.referrals {
                max-width: rem(144);
              }
              &.totalCommission, &.link {
                max-width: rem(228);
              }
              @include breakpoint(mobile) {
                &:not(.name, .totalCommission, .arrow) {
                  display: none;
                }
                &.name, &.totalCommission {
                  max-width: 140px;
                }
              }
            }
          }
          &__body {
            .accordion-item {
              &__head {
                &:hover {
                  background-color: #212121;
                }
                li {
                  &:not(.totalCommission) p {
                    padding: 0 10px;
                  }
                  &.name, &.commission, &.referrals {
                    max-width: rem(144);
                  }
                  &.name, &.link {
                    p {
                      word-break: break-all;
                    }
                  }
                  &.totalCommission, &.link {
                    max-width: rem(228);
                  }
                  &.totalCommission {
                    // display: grid;
                    // grid-template-columns: 12px auto;
                    // gap: 4px;
                    // align-items: center;
                    // img {
                    //   @include same(12px);
                    // }
                  }
                  @include breakpoint(mobile) {
                    &:not(.name, .totalCommission, .arrow) {
                      display: none;
                    }
                    &.name, &.totalCommission {
                      max-width: 140px;
                    }
                  }
                }
              }
              &__body {
                .item-inner {
                  display: grid;
                  grid-auto-rows: auto;
                  gap: rem(34);
                  padding: rem(40);
                  border-top: 1px solid var(--border-subtle-01);
                  @include breakpoint(mobile) {
                    padding: 24px 0;
                    gap: 24px;
                  }
                  &__inner {
                    display: grid;
                    grid-template-columns: auto 580px;
                    gap: 10px;
                    justify-content: space-between;
                    align-items: center;
                    @include breakpoint(mobile) {
                      grid-auto-rows: auto;
                      grid-template-columns: 1fr;
                      gap: 24px;
                      justify-content: flex-start;
                      align-items: flex-start;
                    }

                    .table-section-wrapper .table-section-inner {
                      min-height: auto;
                      margin: 0 !important;
                    }
                    &--total {
                      @include flex(flex-start, flex-start);
                      @include breakpoint(mobile) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 32px 0;
                      }
                      li {
                        display: grid;
                        grid-template-columns: 120px auto;
                        justify-content: space-between;
                        align-items: center;
                        @include breakpoint(mobile) {
                          @include flex;
                        }
                        &:not(:last-of-type) {
                          margin-bottom: rem(24);
                          @include breakpoint(mobile) {
                            margin: 0;
                          }
                        }
                        span {
                          @include string(14px, 18px, 0.16px, 400, left, var(--text-helper));
                          @include breakpoint(mobile) {
                            text-align: center;
                          }
                        }
                        p {
                          @include string(20px, 28px, normal, 700, left, var(--text-primary));
                          @include breakpoint(mobile) {
                            margin-top: 8px;
                            text-align: center;
                          }
                        }
                      }
                    }

                    // @include breakpoint(mobile) {
                    //   display: grid;
                    //   grid-template-columns: repeat(2, 1fr);
                    //   gap: 32px 0;
                    // }
                  }
                  .actions {
                    display: grid;
                    grid-template-columns: auto auto;
                    align-items: flex-end;
                    @include breakpoint(mobile) {
                      grid-template-columns: 1fr;
                      grid-auto-rows: auto;
                      align-items: center;
                      gap: 24px;
                    }
                    &__link {
                      @include flex(flex-start, flex-start);
                      span {
                        @include string(14px, 18px, 0.16px, 400, center, var(--text-helper));
                      }
                      .copy-item-wrapper {
                        background-color: var(--layer-02);
                        padding: 14px;
                        border-radius: var(--box-radius);
                        margin-top: 8px;
                        max-width: rem(466);
                        width: 100%;
                        @include breakpoint(mobile) {
                          max-width: calc(100% - 44px);
                          padding: 14px 22px;
                        }
                        p {
                          @include string(16px, 16px, normal, 400, left, var(--text-primary));
                        }
                      }
                    }
                    &__btn {
                      @include flex(flex-end, center, row);
                      gap: 12px;
                      @include breakpoint(mobile) {
                        justify-content: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .sub-affiliate {
      width: 100%;
      overflow: hidden;
      .error-wrapper {
        height: 55dvh;
        @include breakpoint(mobile) {
          height: 60dvh;
        }
      }
      &__loading {
        @include flex;
        @include same(100%);
        min-height: 30vh;
      }
      .sub-affiliate-inner {
        margin-top: 24px;
        &__info {
          @include flex(flex-start, center, row);
          margin-bottom: 14px;
          gap: 12px;
          p {
            @include string(14px, 18px, 0.16px, 400, left, var(--text-primary));
            span {
              @include string(14px, 18px, 0.16px, 400, left, #F1C21B);
            }
          }
        }
        &__head {
          display: grid;
          grid-template-columns: 230px 120px;
          gap: 15px;
          margin-bottom: 24px;
          @include breakpoint(mobile) {
            flex-wrap: wrap;
            gap: 16px;
            grid-template-columns: 2fr 1fr;
          }
          @media only screen and (max-width: 401px) {
            grid-template-columns: 1fr;
            grid-auto-rows: 1fr;
            gap: 0;
          }
          .base-btn {
            margin-top: 24px;
            @media only screen and (max-width: 401px) {
              margin-top: 12px;
            }
          }
        }
        &__body {
          @include flex(flex-start, flex-end);
          margin-top: 16px;
          .accordion-table-container {
            @include scrollCustom;
            overflow-x: auto;
            width: 100%;
            &__inner {
              min-width: 936px;
              @include breakpoint(mobile) {
                min-width: auto;
              }
              .no-list {
                position: relative;
                min-height: 20vh;
                img {
                  @include size(rem(189), rem(29));
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  @include breakpoint(mobile) {
                    @include size(rem(189, 23), rem(29, 23));
                  }
                }
              }
            }
            &__loading {
              @include flex;
              min-height: 20vh;
            }
            &__body {
              .accordion-item {
                overflow: hidden;
                .copy-item-wrapper {
                  display: inline-block;
                  p {
                    display: inline-block;
                    -webkit-box-orient: vertical;
                    white-space: unset;

                    &::after {
                      content: "";
                      @include same(16px);
                      display: inline-block;
                      margin-left: 4px;
                      transform: translateY(3px);
                      background: url($G-path-icon + 'copy.svg') $G-img-contain;
                    }
                  }
                  .copy-icon {
                    display: none;
                  }
                }
                &__head {
                  cursor: default;
                  &:hover {
                    background-color: #212121;
                  }
                  li {
                    overflow: hidden;
                    width: 100%;
                    &.arrow.num {
                      &::after {
                        display: none !important;
                      }
                    }
                    p {
                      word-break: break-all;
                    }
                  }
                }
                &__body {
                  .user-inner {
                    border-top: 1px solid var(--border-subtle-01);
                    padding: 24px 20px;
                    &__data {
                      display: grid;
                      grid-template-columns: repeat(2, 1fr);
                      gap: 20px 10px;
                      margin-bottom: 32px;
                      li {
                        @include flex(flex-start);
                        span {
                          @include string(12px, 16px, 0.16px, 400, center, var(--text-helper));
                          display: inline-block;
                          margin-bottom: 8px;
                        }
                        p {
                          @include string(14px, 18px, 0.16px, 400, center, var(--text-primary));
                          word-break: break-all;
                        }
                      }
                    }
                    &__inner {
                      .table-section-wrapper .table-section-inner {
                        min-height: auto;
                        margin: 0 !important;
                      }
                      &--info {
                        @include flex(flex-start, flex-start);
                        margin-bottom: rem(32);
                        @include breakpoint(mobile) {
                          margin-bottom: 20px;
                        }

                        .top {
                          @include flex(flex-start, flex-start);
                          margin-bottom: 17px;
                          .user-name {
                            display: grid;
                            grid-template-columns: auto 20px;
                            gap: 4px;
                            align-items: center;
                            margin-bottom: 7px;
                            img {
                              @include same(20px);
                            }
                            p {
                              @include string(16px, 20px, normal, 500, left, var(--text-primary));
                            }
                          }
                          .user-email {
                            display: grid;
                            grid-template-columns: 16px auto;
                            gap: 4px;
                            align-items: center;
                            img {
                              @include same(16px);
                            }
                            p {
                              @include string(14px, 18px, 0.16px, 300, left, var(--text-primary));
                            }
                          }
                        }
                        .date {
                          @include string(12px, 16px, 0.32px, 400, left, var(--text-helper));
                        }
                      }
                    }
                    .actions-btn {
                      @include flex(flex-end, center, row);
                      gap: 12px;
                      margin-top: rem(40);
                      @include breakpoint(mobile) {
                        @include flex;
                        gap: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .referred-content {
      width: 100%;
      overflow: hidden;
      &__head {
        @include flex(space-between, flex-end, row);
        @include breakpoint(mobile) {
          flex-wrap: wrap;
          gap: 16px;
        }
        &--select {
          @include flex(flex-start, flex-start);
          gap: 8px;

          @include breakpoint(tablet) {
            margin-top: 40px;
          }
          p {
            @include string(12px, 16px, 0.32px, 400, left, var(--text-secondary));
          }
          .MuiInputBase-root.base-select {
            width: 264px;
            .MuiSelect-select.MuiSelect-select {
              width: 190px;
            }
          }
        }
      }
      &__body {
        @include flex(flex-start, flex-end);
        margin-top: 16px;
        .accordion-table-container {
          @include scrollCustom;
          overflow-x: auto;
          width: 100%;
          &__inner {
            min-width: 936px;
            @include breakpoint(mobile) {
              min-width: auto;
            }
            .no-list {
              position: relative;
              min-height: 20vh;
              img {
                @include size(rem(189), rem(29));
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include breakpoint(mobile) {
                  @include size(rem(189, 23), rem(29, 23));
                }
              }
            }
          }
          &__head {
            li {
              &.userName, &.totalDeposits, &.totalWagered, &.commissionEarned {
                max-width: rem(144);
              }
              &.userId, &.date {
                max-width: rem(156);
              }
              @include breakpoint(mobile) {
                &:not(.userName, .commissionEarned, .arrow) {
                  display: none;
                }
                &.userName, &.commissionEarned {
                  max-width: 140px;
                }
              }
            }
          }
          &__loading {
            @include flex;
            min-height: 35vh;
          }
          &__body {
            .accordion-item {
              overflow: hidden;
              &__head {
                // cursor: default;
                &:hover {
                  background-color: #212121;
                }
                li {
                  &.arrow {
                    &::after {
                      // display: none !important;
                    }
                  }
                  &:not(.userName) p {
                    padding: 0 10px;
                  }
                  &.userName, &.totalDeposits, &.totalWagered, &.commissionEarned {
                    max-width: rem(144);
                  }
                  &.userName, &.userId {
                    p {
                      word-break: break-all;
                    }
                  }
                  &.userId, &.date {
                    max-width: rem(156);
                  }
                  &.userName {
                    // @include flex(center, center, row);
                    display: grid;
                    grid-template-columns: 16px auto;
                    align-items: center;
                    gap: 4px;
                    .grade-back {
                      @include same(12px);
                      background-color: var(--layer-02);
                      border-radius: 50%;
                      justify-self: center;
                    }
                    .grade-image {
                      @include same(16px);
                    }
                  }
                  @include breakpoint(mobile) {
                    &:not(.userName, .commissionEarned, .arrow) {
                      display: none;
                    }
                    &.userName, &.commissionEarned {
                      max-width: 140px;
                    }
                  }
                }
              }
              &__body {
                .user-inner {
                  border-top: 1px solid var(--border-subtle-01);
                  padding: rem(40);
                  @include breakpoint(mobile) {
                    padding: 24px 20px 40px 20px;
                  }
                  // &__data {
                  //   display: none;
                  //   @include breakpoint(mobile) {
                  //     display: grid;
                  //     grid-template-columns: repeat(2, 1fr);
                  //     gap: 32px 0;
                  //     margin-bottom: 32px;
                  //   }
                  //   li {
                  //     @include flex;
                  //     span {
                  //       @include string(14px, 18px, 0.16px, 400, center, var(--text-helper));
                  //     }
                  //     p {
                  //       @include string(20px, 28px, normal, 700, center, var(--text-primary));
                  //       margin-top: 8px;
                  //     }
                  //   }
                  // }
                  &__inner {
                    .table-section-wrapper .table-section-inner {
                      min-height: auto;
                      margin: 0 !important;
                    }
                    &--info {
                      @include flex(flex-start, flex-start);
                      margin-bottom: rem(32);
                      @include breakpoint(mobile) {
                        margin-bottom: 20px;
                      }

                      .top {
                        @include flex(flex-start, flex-start);
                        margin-bottom: 17px;
                        .user-name {
                          display: grid;
                          grid-template-columns: auto 20px;
                          gap: 4px;
                          align-items: center;
                          margin-bottom: 7px;
                          img {
                            @include same(20px);
                          }
                          p {
                            @include string(16px, 20px, normal, 500, left, var(--text-primary));
                          }
                        }
                        .user-email {
                          display: grid;
                          grid-template-columns: 16px auto;
                          gap: 4px;
                          align-items: center;
                          img {
                            @include same(16px);
                          }
                          p {
                            @include string(14px, 18px, 0.16px, 300, left, var(--text-primary));
                          }
                        }
                      }
                      .date {
                        @include string(12px, 16px, 0.32px, 400, left, var(--text-helper));
                      }
                    }
                  }
                  .actions-btn {
                    @include flex(flex-end, center, row);
                    gap: 12px;
                    margin-top: rem(40);
                    @include breakpoint(mobile) {
                      @include flex;
                      gap: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .fund-content {
      width: 100%;
      &__head {
        @include flex(flex-start, flex-start);
        gap: 8px;

        @include breakpoint(tablet) {
          margin-top: 40px;
        }
        p {
          @include string(12px, 16px, 0.32px, 400, left, var(--text-secondary));
        }
        .MuiInputBase-root.base-select {
          width: 168px;
          .MuiSelect-select.MuiSelect-select {
            width: 96px;
          }
        }
      }
      &__body {
        margin-top: 16px;
        @include breakpoint(mobile) {
        }
        .actions-btn {
          @include flex(flex-end, center, row);
          margin-top: 24px;
          @include breakpoint(mobile) {
            @include flex;
          }
        }
      }
    }
  }
}