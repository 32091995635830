.promotions-wrapper {
  margin-top: 10px;
  @include breakpoint(mobile) {
    margin-top: 0;
  }
  .promotions-inner {
    margin-top: 16px;
    padding-bottom: rem(110);
    &__items {
      .promotions-items-footer {
        @include flex;
        margin: 43px 0 0;
        .more-btn {
          @include flex(flex-start, center, row);
          @include string(14px, 18px, normal, 500, right, var(--text-primary));
          cursor: pointer;
          &::after {
            @include same(24px);
            content: '';
            display: block;
            background: url($G-path-icon + 'more.svg') $G-img-contain;
          }
          &.open::after {
            transform: rotate(180deg);
          }
        }
      }
      .promotions-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(rem(360), 1fr));
        gap: rem(64) rem(24);
        @include breakpoint(mobile) {
          gap: 32px;
        }
        .promotions-item {
          cursor: pointer;
          .thumbnail {
            border-radius: var(--box-radius);
            overflow: hidden;
            aspect-ratio: 360/202;
            img {
              @include same(100%);
              object-fit: cover;
            }
            .tempImage-wrapper {
              aspect-ratio: 360/202;
              @include breakpoint(mobile) {
                border-radius: var(--box-radius);
              }
            }
          }
          .info {
            margin-top: rem(16);
            @include breakpoint(mobile) {
              margin-top: 8px;
            }
            .info-title {
              @include string(20px, 28px, normal, 600, left, var(--text-primary));
              margin-bottom: 4px;
              overflow: hidden;
              white-space: nowrap;
              word-wrap: normal;
              text-overflow: ellipsis;
            }
            .info-date {
              @include string(12px, 16px, 0.32px, 400, left, var(--text-secondary));
            }
          }
        }
      }
    }
    .promotions-list {
      padding: rem(80) 0 0 !important;
      @include breakpoint(mobile) {
        padding: 63px 0 0 !important;
      }
      .warring-msg {
        color: #FFB800;
        margin: 10px 0;
      }
    }
  }
}

.promotion-test-wrapper {
  padding: rem(30) 0 rem(60);
  margin-bottom: rem(60);
  border-bottom: 1px solid var(--border-subtle-03);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(120);
  @include breakpoint(mobile) {
    grid-template-columns: 1fr;
  }
  .box {
    h1 {
      margin-bottom: rem(20);
    }
    &__inner {
      @include flex(flex-start, flex-start);
      gap: 12px;
      max-width: 336px;
    }
    &__foot {
      display: grid;
      grid-auto-rows: 1fr;
      margin-top: rem(26);
    }
  }
}