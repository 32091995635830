.gnb-sidebar {
  position: relative;
  height: 100%;
  z-index: 900;
  .menu-item {
    @include same(40px);
    @include flex;
    border-radius: 4px;
    transition: background-color 0.25s ease;
    cursor: pointer;
    position: relative;
    &.isActive, &:hover {
      background-color: var(--layer-hover-01);
    }
  }
  .icon-r {
    @each $icon in casino, slot, unexpand, expand, wallet, vault, vip, affiliate, statistics, history, promotion, settings, policies, language, livesupport {
      &.#{$icon} {
        &::after {
          @include same(20px);
          background: url($G-path-icon + 'leftbar-button-#{$icon}.svg') $G-img-contain;
          content: '';
          display: block;
        }
      }
    }
  }
  .gnb-side-bar-header {
    @include flex;
    padding: 4px;
    border-bottom: 1px solid var(--border-subtle-01);
    &.rows {
      @include flex(space-between, flex-start);
      padding: 4px 4px 15px 12px;
      min-height: 74px;
      max-height: 74px;
      .head {
        @include flex(space-between, center, row);
        width: 100%;
        .logo {
          @include size(rem(103), rem(16));
          background: url($G-path-logo + 'logo_image_gray.png') $G-img-contain;
          -webkit-animation: fadeIn ease-in 0.25s;
          animation: fadeIn ease-in 0.25s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
        }
      }
      .body {
        width: 100%;
        -webkit-animation: fadeIn ease-in 0.25s;
        animation: fadeIn ease-in 0.25s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        .userOut {
          display: grid;
          grid-auto-rows: auto;
        }
        .userIn {
          @include flex(space-between, center, row);
          padding-right: 12px;
          &__inner {
            display: grid;
            grid-template-columns: 20px auto;
            gap: 4px;
            align-items: center;
            .grade {
              @include same(20px);
            }
            .userInfo {
              @include flex(flex-start, flex-start);
              p {
                @include string(14px, 14px, normal, 500, left, var(--text-primary));
                margin-bottom: 1px;
              }
              span {
                @include string(12px, 12px, 0.32px, 400, left, var(--text-helper));
              }
            }
          }
          .logout-btn {
            min-width: 58px;
            height: 16px;
            padding: 0 6px;
            border-radius: var(--box-radius);
            background: var(--button-button-secondary, #6F6F6F);
            @include string(12px, 16px, 0.32px, 400, center, var(--text-primary));
          }
        }
      }
    }
  }
  .gnb-sidebar-small {
    background: var(--layer-01);
    border-right: 1px solid var(--border-subtle-00);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.50);
    height: 100%;
    width: var(--G-gnbside-small-width);
    transform: translateX(0);
    transition: width 0.25s ease, transform 0.25s ease;
    @include breakpoint(tablet) {
      position: absolute;
      top: 0;
      left: 0;
    }
    &:not(.init).show {
      .gnb-sidebar-small__list {
        -webkit-animation: fadeInUp ease-in 0.25s;
        animation: fadeInUp ease-in 0.25s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
      }
    }
    &.hidden {
      transform: translateX(-100%);
    }

    &__list {
      @include scrollCustomSmall;
      overflow-y: auto;
      height: calc(100% - 57px); // padding top,bottom + menu height 값 빼기
      padding: 4px 0;
      .menu-group {
        display: grid;
        grid-auto-rows: auto;
        gap: 4px;
        padding: 0 4px;
        &:not(:last-of-type) {
          border-bottom: 1px solid var(--border-subtle-01);
        }
        .menu-item {
          &:first-of-type {
            margin-top: 4px;
          }
          &:last-of-type {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  .gnb-sidebar-normal {
    background: var(--layer-01);
    border-right: 1px solid var(--border-subtle-00);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.50);
    height: 100%;
    width: var(--G-gnbside-width);
    // position: absolute;
    // top: 0;
    // left: 0;
    transform: translateX(0);
    transition: width 0.25s ease, transform 0.25s ease;
    &.layer-sidebar {
      position: fixed;
      top: 0;
      left: 0;
    }
    &:not(.init).show {
      .gnb-side-bar-header .profile-details {
        -webkit-animation: fadeIn ease-in 0.25s;
        animation: fadeIn ease-in 0.25s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
      }
      .gnb-sidebar-normal__list {
        -webkit-animation: fadeInUp2 ease-in 0.25s;
        animation: fadeInUp2 ease-in 0.25s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
      }
    }
    &.hidden {
      transform: translateX(-100%);
    }
    &__list {
      @include scrollCustomSmall;
      overflow-y: auto;
      height: calc(100% - 94px);
      .menu-details {
        &:not(:last-of-type) {
          border-bottom: 1px solid var(--border-subtle-01);
        }
        padding: 20px 16px;
        .menu-details__title {
          @include string(14px, 18px, normal, 500, left, var(--text-helper));
          @include disableDrag;
        }
        .menu-details__content {
          @include flex(flex-start, flex-start);
          margin-top: rem(8);
          li {
            width: calc(100% - 12px);
            cursor: pointer;
            &:not(:last-of-type) {
              margin-bottom: 4px;
            }
            padding: 10px 4px 10px 8px;
            display: grid;
            grid-template-columns: 20px auto;
            gap: 8px;
            align-items: center;
            border-radius: 4px;
            transition: background-color 0.25s ease;
            .name {
              @include string(14px, 18px, normal, 500, left, var(--text-primary));
              @include disableDrag;
              white-space: nowrap;
            }
            &.isActive, &:hover {
              background-color: var(--layer-hover-01);
            }
          }
        }

      }
    }
  }
}

.m-gnb-sidebar {
  background: var(--background-default);
  position: fixed;
  top: var(--G-header-hegiht-m);
  left: 0;
  z-index: 900;
  width: 100%;
  height: calc(100% - var(--G-header-hegiht-m) - var(--G-navigation-hegiht-m));
  transition: width 0.25s ease, transform 0.25s ease;
  &.close {
    transform: translateY(calc(100% + var(--G-navigation-hegiht-m)));
  }
  &.open {
    transform: translateY(0);
  }
  .icon-r {
    @each $icon in casino, slot, unexpand, expand, wallet, vault, vip, affiliate, statistics, promotion, history, settings, policies, language, livesupport {
      &.#{$icon} {
        &::after {
          @include same(20px);
          background: url($G-path-icon + 'leftbar-button-#{$icon}.svg') $G-img-contain;
          content: '';
          display: block;
        }
      }
    }
  }
  &__inner {
    padding: 24px 16px 0;
    height: 100%;
    box-sizing: border-box;
    .m-gnb-sidebar-header {
      margin-bottom: 26px;
    }
    .m-gnb-sidebar-list {
      border-radius: var(--box-radius);
      background: var(--field-01);
      box-sizing: border-box;
      height: 100%;
      &__inner {
        @include scrollCustomSmall;
        overflow-y: auto;
        height: calc(100% - 78px);
        .search-details {
          padding: 20px 16px;
          .infos {
            @include string(14px, 18px, 0.16px, 400, center, var(--text-helper));
            padding-top: 16px;
          }
          .loading-wrap {
            @include flex;
          }
          .result {
            &__title {
              @include string(14px, 18px, 0.16px, 400, left, var(--text-placeholder));
            }
            &__list {
              @include scrollCustom;
              overflow-y: auto;
              li {
                margin-top: 16px;
                p {
                  @include string(16px, 22px, normal, 500, left, var(--text-primary));
                  cursor: pointer;
                  display: inline-block;
                  &::after {
                    @include same(16px);
                    background: url($G-path-icon + 'link.svg') $G-img-contain;
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 4px;
                    transform: translateY(-1px);
                  }
                }
              }
            }
          }
        }
        .menu-details {
          &:not(:last-of-type) {
            border-bottom: 1px solid var(--border-subtle-01);
          }
          padding: 20px 16px;
          .menu-details__title {
            @include string(14px, 18px, normal, 500, left, var(--text-helper));
            @include disableDrag;
          }
          .menu-details__content {
            @include flex(flex-start, flex-start);
            margin-top: 8px;
            li {
              width: calc(100% - 12px);
              cursor: pointer;
              &:not(:last-of-type) {
                margin-bottom: 4px;
              }
              padding: 10px 4px 10px 8px;
              display: grid;
              grid-template-columns: 20px auto;
              gap: 8px;
              align-items: center;
              border-radius: 4px;
              transition: background-color 0.25s ease;
              .name {
                @include string(14px, 18px, normal, 500, left, var(--text-primary));
                @include disableDrag;
                white-space: nowrap;
              }
              &.isActive, &:hover {
                background-color: var(--layer-hover-01);
              }
            }
          }

        }
      }
    }
  }
}

.option-sidebar {
  position: relative;
  z-index: 900;
  /* TODO: 모바일 화면에서 option sidebar와 gnb sidebar 레이어 순서 겹치지않게
     모바일은 1개의 sidebar가 노출될수 있게 수정 */
  &.hidden {
    .option-sidebar-inner {
      width: 0;
      transform: translateX(100%);
    }
  }
  border-left: 1px solid var(--border-subtle-01, #393939);
  background: var(--background-default, #161616);

  /* shadow-default */
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.50);
  .option-sidebar-inner {
    background: var(--layer-01);
    // border-left: 1px solid var(--border-subtle-00);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.50);
    height: 100%;
    box-sizing: border-box;
    transition: width 0.25s ease, transform 0.25s ease;
    transform: translateX(0);
    width: var(--G-optionside-width);
    p {
      color: #fff;
    }
  }
}
.m-option-sidebar {
  position: fixed;
  z-index: 900;
  top: var(--G-header-hegiht-m);
  left: 0;
  width: 100%;

  height: calc(100% - var(--G-header-hegiht-m) - var(--G-navigation-hegiht-m));
  background: var(--layer-01);
  transition: transform 0.25s ease;
  transform: translateX(0);
  &.hidden {
    transform: translateY(calc(100% + var(--G-navigation-hegiht-m)));
  }
  .option-sidebar-inner {
    height: 100%;
    p {
      color: #fff;
    }
  }
}

.option-sidebar, .m-option-sidebar {
  .option-sidebar-inner {
    &.chat {
      .option-sidebar-inner__head {
        @include flex(space-between, center, row);
        background: var(--layer-01);
        // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.50);
        height: 56px;
        padding: 0 8px 0 21px;
        position: relative;
        z-index: 5;
        .close-btn {
          @include same(40px);
          background: url($G-path-icon + 'modal-close.svg') $G-img-contain;
          position: absolute;
          top: 8px;
          right: 8px;
          z-index: 99;
          transition: background 0.2s ease-out;
          &:hover {
            background: url($G-path-icon + 'modal-close-hover.svg') $G-img-contain;
          }
          &:active {
            background: url($G-path-icon + 'modal-close-active.svg') $G-img-contain;
          }
        }
        .channel-wrapper {
          position: relative;
          .current-lang {
            display: grid;
            grid-template-columns: 24px auto;
            gap: 9px;
            align-items: center;
            cursor: pointer;
            &.active .current::after {
              transform: rotate(-180deg);
            }
            .symbol {
              @include flex;
              @include same(24px);
              border-radius: 50%;
              p {
                @include string(12px, 12px, normal, 500, left, var(--text-primary));
                text-transform: uppercase;
              }
            }
            .current {
              @include flex(flex-start, center, row);
              &::after {
                @include same(24px);
                content: '';
                display: block;
                background: url($G-path-icon + 'arrow_bottom.svg') $G-img-contain;
                transition: transform 0.25s ease;
              }
              .text {
                @include string(16px, 20px, normal, 500, left, var(--text-primary));
              }
            }
          }
          .select-lang {
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            z-index: 10;
            background: var(--background-inverse);
            border-radius: var(--box-radius);
            min-width: rem(134);
            &::before {
              @include same(12px);
              content: '';
              display: block;
              background: var(--background-inverse);
              position: absolute;
              top: -4px;
              left: 50%;
              transform: translateX(-50%) rotate(-45deg);
              z-index: 9;
            }
            &__list {
              padding: rem(8) 0;
              .list-item {
                @include disableDrag;
                padding: rem(12) rem(16);
                cursor: pointer;
                display: grid;
                grid-template-columns: 24px auto;
                align-items: center;
                gap: 8px;
                &:hover {
                  background-color: var(--background-inverse-hover);
                }
                p {
                  @include string(16px, 20px, normal, 700, left, var(--text-inverse));
                }
                .symbol {
                  @include same(24px);
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
      .option-sidebar-inner__body {
        @include scrollCustom;
        overflow-y: auto;
        height: 100%;
        max-height: calc(100% - (116px + 56px));
        background: var(--background-default);
        // padding: 0 0 10px;
        scroll-behavior: smooth;
        overflow-x: hidden;
        position: relative;
        .new-messages-bar {
          @include flex;
          position: fixed;
          top: 56px;
          left: 0;
          transform: translateY(-24px);
          background: #B795FF;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.50);
          height: 24px;
          width: 100%;
          z-index: 1;
          cursor: pointer;
          transition: 0.15s ease transform;
          &.isActive {
            transform: translateY(0);
          }
          p {
            @include string(14px, 18px, normal, 700, left, var(--text-inverse));
          }
        }
        .info-center {
          @include flex;
          height: 100%;
          p {
            @include string(14px, 18px, normal, 500, left, var(--text-secondary));
          }
        }
        .message {
          display: grid;
          grid-auto-rows: auto;
          gap: 8px;
          // padding: 0 16px;
          padding: 10px 11px 10px 16px;
          .message-item {
            display: grid;
            grid-template-columns: 20px auto;
            gap: 4px;
            padding: 7px 16px 7px 8px;
            border-radius: var(--box-radius);
            background: var(--layer-01);
            &.isMe {
              background: var(--layer-03);

            }
            .user-grade {
              @include same(20px);
            }
            .content {
              @include string(14px, 20px, 0.16px, 500, left, var(--text-secondary));
              transform: translateY(-1px);
              word-break: break-all;
            }
          }
        }
      }
      .option-sidebar-inner__foot {
        padding: 16px;
        background: var(--layer-01);
        height: calc(116px - 32px);
        .chat-info {
          @include string(14px, 18px, 0.16px, 400, left, var(--link-primary));
          margin-top: 8px;
          -webkit-animation: fadeIn ease-in 0.25s;
          animation: fadeIn ease-in 0.25s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
        }
      }
    }
    &.noti {
      .option-sidebar-inner__head {
        @include flex(space-between, center, row);
        background: var(--layer-01);
        height: 56px;
        padding: 0 8px 0 21px;
        position: relative;
        z-index: 5;
        .info-wrapper {
          @include flex(space-between, center, row);
          width: calc(100% - 48px);
          &__title {
            @include string(16px, 20px, normal, 500, left, var(--text-primary));
          }
          .readAll-btn {
            @include flex;
            @include size(58px, 16px);
            @include string(12px, 16px, 0.32px, 400, left, var(--text-primary));
            border-radius: var(--box-radius);
            background: var(--button-button-secondary, #6F6F6F);
          }
        }
        .close-btn {
          @include same(40px);
          background: url($G-path-icon + 'modal-close.svg') $G-img-contain;
          position: absolute;
          top: 8px;
          right: 8px;
          z-index: 99;
          transition: background 0.2s ease-out;
          &:hover {
            background: url($G-path-icon + 'modal-close-hover.svg') $G-img-contain;
          }
          &:active {
            background: url($G-path-icon + 'modal-close-active.svg') $G-img-contain;
          }
        }
      }
      .option-sidebar-inner__body {
        @include scrollCustom;
        overflow-y: auto;
        height: 100%;
        max-height: calc(100% - 56px);
        background: var(--background-default);
        scroll-behavior: smooth;
        overflow-x: hidden;
        position: relative;
        .info-center {
          @include flex;
          height: 100%;
          p {
            @include string(14px, 18px, normal, 500, left, var(--text-secondary));
          }
        }
        .noti-list {
          display: grid;
          grid-auto-rows: auto;
          gap: 16px;
          padding: 8px 16px;
          .noti-list-item {
            @include flex(flex-start, flex-start);
            border-radius: var(--box-radius);
            background: var(--layer-01);
            padding: 13px 16px 8px;
            position: relative;
            &.new {
              &::after {
                @include same(8px);
                content: "";
                display: block;
                position: absolute;
                top: 12px;
                right: 12px;
                background-color: var(--support-error);
                border-radius: 50%;
              }
            }
            .date {
              @include string(12px, 16px, 0.32px, 400, left, var(--text-helper));
            }
            .title {
              @include string(14px, 18px, normal, 500, left, var(--text-primary));
              margin: 4px 0;
            }
            .content {
              @include string(14px, 20px, 0.16px, 400, left, var(--text-secondary));
              &.normal {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
              }
            }
            .showAll-btn {
              margin-top: 8px;
              align-self: flex-end;
              &.isActive {
                p::after {
                  transform: rotate(-180deg);
                }
              }
              p {
                @include flex(flex-start, center, row);
                @include string(14px, 18px, normal, 500, left, var(--text-primary));
                &::after {
                  @include same(24px);
                  content: '';
                  display: block;
                  background: url($G-path-icon + 'arrow_bottom.svg') $G-img-contain;
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-container {
  &__drop {
    @include same(100%);
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 850;
    -webkit-animation: fadeIn ease-in 0.2s;
    animation: fadeIn ease-in 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .search-view {
    @-webkit-keyframes fadeInUp3{
      0%{transform:translate(-50%, 20px); opacity: 0;}
      100%{transform:translate(-50%, 0); opacity: 1;}
    }
    @-moz-keyframes fadeInUp3{
      0%{transform:translate(-50%, 20px); opacity: 0;}
      100%{transform:translate(-50%, 0); opacity: 1;}
    }
    @keyframes fadeInUp3{
      0%{transform:translate(-50%, 20px); opacity: 0;}
      100%{transform:translate(-50%, 0); opacity: 1;}
    }
    @include size(calc(100% - 32px), auto);
    max-width: rem(1016);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--layer-01);
    z-index: 859;
    -webkit-animation: fadeInUp3 ease-in 0.25s;
    animation: fadeInUp3 ease-in 0.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    &__inner {
      padding: 24px 24px 32px;
      .infos {
        @include string(14px, 18px, 0.16px, 400, center, var(--text-helper));
        margin-top: 24px;
      }
      .loading-wrap {
        @include flex;
        margin-top: 24px;
      }
      .result {
        padding: 16px 0 0;
        &__title {
          @include string(14px, 18px, 0.16px, 400, left, var(--text-placeholder));
          padding-bottom: 16px;
        }
        &__list {
          max-height: 26vh;
          @include scrollCustom;
          overflow-y: auto;
          li {
            // &:not(:first-of-type) {
            //   margin-top: 16px;
            // }
            padding: 8px 0;
            &:hover {
              background: var(--layer-hover-01);;
              cursor: pointer;
            }
            p {
              @include string(16px, 22px, normal, 500, left, var(--text-primary));
              display: inline-block;
              &::after {
                @include same(16px);
                background: url($G-path-icon + 'link.svg') $G-img-contain;
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-left: 4px;
                transform: translateY(-1px);
              }
            }
          }
        }
      }
    }
  }
}