footer {
  background-color: var(--layer-01);
  width: 100%;
  .footer {
    @include flex;
    padding: rem(40) 0 rem(58);
    @include breakpoint(mobile) {
      padding: 58px 0 72px;
    }
    &__head {
      @include flex;
      img {
        object-fit: contain;
        height: 21px;
        margin-bottom: 12px;
      }
      p {
        @include string(12px, 16px, 0.32px, 400, center, var(--text-primary));
      }
    }
    &__body {
      @include flex;
      margin-top: rem(40);
      @include breakpoint(mobile) {
        padding: 0 16px;
      }
      li {
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
        p {
          @include string(12px, 16px, 0.32px, 400, center, var(--text-secondary));
          br {
            @include breakpoint(mobile) {
              display: none;
            }
          }
        }
        .email-wrapper {
          @include flex(space-between, center, row);
          gap: 3px;
          @include breakpoint(mobile) {
            display: grid;
            grid-auto-rows: auto;
            gap: 0;
          }
          span {
            @include string(12px, 16px, 0.32px, 300, center, var(--text-secondary));
            @include breakpoint(mobile) {
              display: none;
            }
          }
          a {
            color: var(--link-link-primary, #FFD56A) !important;
            text-decoration: underline;
          }
        }
      }
    }
  }
}