.settings-wrapper {
  min-height: 60vh;
  .section-inner.settings-inner {
    @include breakpoint(tablet) {
      display: grid !important;
      grid-auto-rows: auto !important;
      grid-template-columns: 1fr !important;
      gap: 16px;
    }
    .settings-content {
      background-color: var(--layer-01);
      border-radius: var(--box-radius);
      padding: 24px 24px 32px;
      @include breakpoint(mobile) {
        padding: 24px;
      }

      &__title {
        p {
          @include string(16px, 20px, normal, 600, left, var(--text-primary));
        }
        span {
          @include string(14px, 20px, 0.16px, 400, left, var(--text-secondary));
          display: inline-block;
          margin-top: 8px;
        }
      }
      &__inner {
        margin-top: 24px;
      }
    }
    // General
    .general-content {
      &__inner {
        .form-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          // align-items: flex-end;
          gap: rem(24);
          @include breakpoint(mobile) {
            grid-template-columns: 1fr;
            gap: 16px;
          }
          &__inner {
            @include flex(flex-start, flex-start, row);
            gap: rem(20);
            margin-top: 22px;
            @include breakpoint(mobile) {
              @include flex(flex-start, flex-end);
              gap: 0;
              margin-top: 0;
              .base-btn {
                &:not(:last-of-type) {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
    // Security
    .security-content {
      &.isDisable {
        padding: 24px;
      }
      .disable-layout {
        @include flex(space-between, center, row);
        @include breakpoint(mobile) {
          @include flex(flex-start, flex-start);
        }
        &__inner {
          @include breakpoint(mobile) {
            @include flex(flex-start, flex-end);
            width: 100%;
            margin-top: 24px;
          }
        }
      }
      &__inner {
        .form-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: flex-start;
          gap: rem(24);
          @include breakpoint(mobile) {
            grid-template-columns: 1fr;
            gap: 16px;
          }
          &__inner {
            @include flex(flex-start, flex-start, row);
            margin-top: 22px;
            @include breakpoint(mobile) {
              @include flex(flex-start, flex-end);
              margin-top: 0;
            }
          }
        }
      }
    }
    // Preference
    .preference-content {
      padding: 24px;
      .box-layout {
        @include flex(space-between, center, row);
        @include breakpoint(mobile) {
          @include flex(flex-start, flex-start);
        }
        &:not(:last-of-type) {
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 1px solid #393939;
        }
        &__inner {
          @include breakpoint(mobile) {
            @include flex(flex-start, flex-end);
            width: 100%;
            margin-top: 24px;
          }
        }
      }
    }
    // Redeem
    .redeem-content {
      padding: 24px;
      &__inner {
        .form-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: flex-start;
          gap: rem(24);
          @include breakpoint(mobile) {
            grid-template-columns: 1fr;
            gap: 16px;
          }
          &__inner {
            @include flex(flex-start, flex-start, row);
            margin-top: 4px;
            @include breakpoint(mobile) {
              @include flex(flex-start, flex-end);
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}