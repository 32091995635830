
@container table-section (max-width: 768px) {
  .table-section-inner {
    margin: 24px 0 0 !important;
  }
}

@container table-section (max-width: 400px) {
  .table-section-inner {
    .table-header {
      display: grid !important;
      grid-auto-rows: auto !important;
      gap: 16px !important;
      justify-content: normal !important;
      .base-date-picker-wrapper {
        display: grid !important;
        grid-auto-rows: auto !important;
        justify-content: normal !important;
      }
    }
  }
}
.table-section-list {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 20px;
}
.table-section-wrapper {
  container: table-section / inline-size;
  .table-section-inner {
    min-height: 50vh;
    @include breakpoint(mobile) {
      margin: 24px 0 0;
    }
    .table-header {
      @include flex(flex-start, center, row);
      margin: 24px 0 16px;
      gap: 22px;
      flex-wrap: wrap;
      @include breakpoint(mobile) {
        // display: none;
      }
      .base-date-picker-wrapper {
        @include flex(flex-start, center, row);
        gap: 16px;
      }
      .base-select {
        @include breakpoint(tablet) {
          display: none;
        }
      }
      // &__inner, .date-select-wrapper, .date-select, .date-select {
      //   @include flex(flex-start, center, row);
      // }
      // &__inner {
      //   gap: 22px;
      //   flex-wrap: wrap;
      //   .date-select-wrapper {
      //     gap: 8px;
      //     flex-wrap: wrap;
      //     .date-select {
      //       gap: 8px;
      //       flex-wrap: wrap;
      //       li {
      //         display: grid;
      //         grid-template-columns: 62px auto;
      //         gap: 4px;
      //         align-items: center;
      //         p {
      //           @include string(16px, 20px, normal, 600, left, var(--text-primary));
      //         }
      //       }
      //     }
      //   }
      // }
    }
    .table-container {
      @include scrollCustom;
      overflow-x: auto;
      width: 100%;
      .not-list {
        min-height: 30vh;
        position: relative;
        width: 100%;
        min-width: 1128px;
        p {
          @include string(14px, 18px, 0.16px, 400, center, var(--text-helper));
          position: sticky;
          top: 50%;
          left: calc(50% - 24px);
          display: inline;
        }
      }

      .no-list {
        @include flex;
        min-height: 30vh;
        p {
          @include string(14px, 18px, 0.16px, 400, center, var(--text-helper));
        }
      }

      &.fund .table-inner {
        min-width: var(--G-aside-content-width);
        @include breakpoint(mobile) {
          min-width: 621px;
        }

        colgroup {
          col {
            width: 312px;
            @include breakpoint(mobile) {
              width: 207px;
            }
          }
        }
      }
      &.item-list .table-inner {
        min-width: 580px;

        colgroup {
          col {
            &.cryptos {
              // width: 20%;
            }
          }
        }
      }
      &.user-list .table-inner {
        min-width: 856px;

        colgroup {
          col {
            // &:not(.cryptos) {
            //   width: 146.8px;
            // }
            // .cryptos {
            //   width: 122px;
            // }
          }
        }
      }
      &.promotions .table-inner {
        min-width: 1128px;

        colgroup {
          col {
            &.date {
              width: 116px;
            }
            &.title {
              width: 140px;
            }
            &.depositAmount,
            &.currency,
            &.bonusAmount,
            &.wagerCondition,
            &.wagered {
              width: 98px;
            }
            &.time {
              width: 168px;
            }
          }
        }
        .table-inner {
          &__head {
            th {
              padding: 8px 0;
              height: calc(48px - 16px);
            }
          }
          &__body {
            td {
              padding: 0;
              height: 48px;
              .double {
                @include flex(center, center, row);
                gap: 8px;
              }
              .solo {
                @include flex;
              }
              .icon-btn {
                height: 32px;
                p {
                  font-weight: 600;
                  color: var(--text-inverse);
                }
              }
              .base-btn {
                &.primary p {
                  color: var(--text-inverse);
                }
                p {
                  font-weight: 600;
                }
              }
              .complete, .canceled {
                font-weight: 500;
              }
              .complete {
                @include flex(flex-start, center, row);
                gap: 6px;
                &::before {
                  @include same(16px);
                  content: '';
                  display: block;
                  background: url($G-path-icon + 'verified.svg') $G-img-contain;
                  filter: invert(100%);
                }
              }

              p {
                font-weight: 300;
              }
              &.date {
                padding: 0 15px;
              }
            }
          }
        }
      }
      &.history .table-inner {
        min-width: 1128px;

        colgroup {
          col {
            &.date {
              width: 168px;
            }
            &.cryptos {
              width: 120px;
            }
            &.tXID {
              width: 264px;
            }
            &.transactionType, &.amount, &.fees &.netAmount {
              width: 144px;
            }
          }
        }
      }
      &.statistics .table-inner {
        min-width: 1128px;

        colgroup {
          col {
            &.cryptos {
              width: 120px;
            }
          }
        }
      }
      .table-inner {
        width: 100%;
        overflow: hidden;
        table-layout: fixed;
        border-top-left-radius: var(--box-radius);
        border-top-right-radius: var(--box-radius);
        td {
          border-bottom: 1px solid var(--border-subtle-01);
        }
        &__head {
          background-color: var(--layer-01);
          border-top: 1px solid var(--black);
          overflow: hidden;

          th {
            @include string(12px, 16px, 0.32px, 400, center, var(--text-helper));
            padding: 16px 0;
            vertical-align: middle;
            @include breakpoint(mobile) {
              // text-align: left;
              // padding: 24px;
            }
          }
        }
        &__body {
          background-color: var(--white);
          tr:last-of-type td {
            border-bottom: 1px solid var(--border-subtle-03);
          }
          td {
            vertical-align: middle;
            padding: 15px 0;
            p {
              @include string(14px, 18px, 0.16px, 400, center, var(--text-primary));
              vertical-align: middle;
              word-break: break-all;
              &.link-active {
                color: #FFD56A;
                cursor: pointer;
              }
              &.link {
                overflow: hidden;
                white-space: nowrap;
                word-wrap: normal;
                text-overflow: ellipsis;
              }
            }
            &.token {
              .token__inner {
                display: grid;
                grid-template-columns: auto 12px;
                gap: 4px;
                align-items: center;
                justify-content: center;
                .token-image {
                  @include same(12px);
                }
              }
            }
            &.symbol {
              .symbol__inner {
                display: grid;
                grid-template-columns: 12px auto;
                gap: 4px;
                align-items: center;
                justify-content: center;
                .symbol-image {
                  @include same(12px);
                }
              }
            }
            &.txId {
              padding: 15px 30px;
            }
          }
        }
      }
    }
  }
}



.accordion-table-container {
  width: 100%;
  &__head {
    @include flex(space-between, center, row);
    border-top-left-radius: var(--box-radius);
    border-top-right-radius: var(--box-radius);
    background-color: var(--layer-01);
    li {
      @include flex;
      padding: 16px 0;
      width: 100%;
      vertical-align: middle;
      &.arrow {
        max-width: 48px;
      }
      p {
        @include string(12px, 16px, 0.32px, 400, center, var(--text-helper));
        padding: 0 10px;
      }
    }
  }
  &__body {
    display: grid;
    grid-auto-rows: auto;
    .accordion-item {
      &:last-of-type {
        border-bottom: 1px solid var(--border-subtle-03);
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--border-subtle-01);
      }
      &__head {
        @include flex(space-between, center, row);
        cursor: pointer;
        li {
          @include flex;
          padding: 15px 0;
          width: 100%;
          vertical-align: middle;
          p {
            @include string(14px, 18px, 0.16px, 400, center, var(--text-primary));
            // padding: 0 10px;
          }
          &.arrow {
            max-width: 48px;
            &.active::after {
              transform: rotate(-180deg);
            }
            &::after {
              @include same(24px);
              content: '';
              display: block;
              background: url($G-path-icon + 'arrow_bottom.svg') $G-img-contain;
              transition: transform 0.25s ease;
            }
          }
        }
      }
      &__body {
        height: 0;
        overflow: hidden;
        transition: height 0.25s ease;
        &.show {
          height: auto;
          // transition: height 0.25s ease;
        }
      }
    }
  }
}