.licenses-wrapper {
  margin-top: 10px;
  .licenses-inner {
    display: grid;
    grid-auto-rows: auto;
    gap: 24px;
    margin-top: 16px;
    @include breakpoint(mobile) {
      gap: 16px;
    }
    .info-box {
      border-radius: var(--box-radius);
      background-color: var(--layer-01);
      padding: 20px 26px 24px;
      @include breakpoint(mobile) {
        padding: 20px 24px 24px;
      }
      &__head {
        @include flex(flex-start, center, row);
        gap: 12px;
        padding-bottom: 20px;
        margin-bottom: 25px;
        border-bottom: 1px solid #393939;
        @include breakpoint(mobile) {
          margin-bottom: 24px;
        }
        &--title {
          @include string(16px, 20px, normal, 500, left, var(--text-primary));
        }
      }
      &__body {
        p {
          @include string(16px, 22px, normal, 400, left, var(--text-secondary));
          a {
            color: var(--link-primary) !important;
          }
        }
      }
    }
  }
}

.policies-wrapper {
  margin-top: 10px;
  .policies-inner {
    margin-top: 32px;
    @include breakpoint(mobile) {
      margin-top: 16px;
    }
    .policy-viewer-header {
      display: grid;
      grid-template-columns: auto 263px;
      gap: 20px;
      align-items: center;
      @include breakpoint(mobile) {
        @include flex(flex-start, flex-start, column-reverse);
        gap: 0;
        width: 100%;
      }
      .state-title {
        @include string(16px, 20px, normal, 500, left, var(--text-primary));
        @include breakpoint(mobile) {
          margin-top: 16px;
        }
      }
      .MuiInputBase-root.base-select {
        @include breakpoint(mobile) {
          width: 100%;
        }
        .MuiSelect-select.MuiSelect-select {
          @include breakpoint(mobile) {
            width: 100%;
          }
        }
      }
    }
    .policy-viewer-body {
      margin-top: 16px;

      &__inner {
        ul {
          margin-bottom: rem(22);
          li {
            &:not(:last-of-type) {
              margin-bottom: rem(12);
            }
            p {
              &::before {
                content: '·';
                display: inline-block;
                margin-right: 3px;
              }
              text-indent: -3px;
              margin-left: 3px;
            }
          }
        }
        p {
          &:not(:last-of-type) {
            margin-bottom: rem(22);
          }
          @include string(rem(16), rem(24), normal, 300, left, var(--text-primary));
          @include breakpoint(mobile) {
            @include string(14px, 20px, 0.16px, 400, left, var(--text-primary));
          }
          .link {
            color: var(--link-primary);
          }
          &.strong {
            font-weight: 500;
            font-size: rem(20);
          }
        }
      }
    }
  }
}