// img or size
$G-path: "../../assets/";
$G-path-content: $G-path + "Content/";
$G-path-icon: $G-path + "Icon/";
$G-path-symbol: $G-path + "Symbol/";
$G-path-logo: $G-path + "Logo/";
$G-path-grade: $G-path + "Grade/";
$G-path-tag: $G-path + "Tag/";
$G-img-cover: no-repeat center / cover;
$G-img-contain: no-repeat center / contain;
// $G-max-width: 1128px;
// $G-modal-max-width: 48.72rem;

$box-round: 10px;
$box-round-small: 5px;


@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

$browser-context: 18;
@function rem($pixels, $context: $browser-context) {
  // @return #{$pixels/$context}rem;
  @return #{calc($pixels / $context)}rem;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}