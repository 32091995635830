.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  .main-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: left 0.25s ease, width 0.25s ease;
    @include breakpoint(tablet) {
      padding-left: var(--G-gnbside-small-width);
      width: calc(100% - var(--G-gnbside-small-width));
    }
    @include breakpoint(mobile) {
      padding-left: 0;
      width: 100%;
      position: static;
      height: auto;
      margin: var(--G-header-hegiht-m) 0 var(--G-navigation-hegiht-m);
    }
    .content-wrapper {
      @include flex(flex-start, center);
      @include scrollCustom;
      width: calc(100% - 6vw);
      padding: 0 3vw;
      height: calc(100% - var(--G-header-hegiht));
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      @include breakpoint(mobile) {
        width: calc(100% - 32px);
        padding: 0 16px;
        height: 100%;

      }
      .container {
        max-width: var(--G-max-width);
        width: 100%;
        .section-wrapper {
          padding: rem(40) 0 rem(240);
          @include breakpoint(mobile) {
            padding: 39px 0 rem(240);
          }
          &__header {
            &.select-rows {
              display: grid;
              grid-template-columns: auto 154px;
              justify-content: space-between;
              align-items: center;
            }
            p {
              @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
              display: grid;
              grid-template-columns: 20px auto;
              gap: 8px;
              align-items: center;
              @include breakpoint(mobile) {
                @include string(16px, 20px, normal, 500, left, var(--text-primary));
              }
              @each $icon in affiliate, history, statistics, license, settings, promotions, policies, casino, slot {
              &.#{$icon} {
                  &::before {
                    @include same(20px);
                    background: url($G-path-icon + 'content-header-#{$icon}.svg') $G-img-contain;
                    content: '';
                    display: block;
                  }
                }
              }
            }
          }
          .section-inner {
            display: grid;
            grid-template-columns: 168px minmax(auto, var(--G-aside-content-width));
            gap: rem(24);
            align-items: flex-start;
            position: relative;
            margin-top: 16px;
            @include breakpoint(tablet) {
              @include flex(flex-start, flex-start);
              gap: 16px;
            }
            .section-select {
              .MuiInputBase-root.base-select {
                width: 242px;
                .MuiSelect-select.MuiSelect-select {
                  width: 170px;
                }
              }
            }
            .section-asidebar {
              position: sticky;
              top: 16px;
              left: 0;
              padding: 8px 0;
              background-color: var(--layer-01);
              border-radius: var(--box-radius);
              @include breakpoint(tablet) {
                position: static;
              }
              .section-asidebar-menu {
                display: grid;
                grid-auto-rows: auto;
                &__item {
                  padding: 11px 16px;
                  position: relative;
                  cursor: pointer;
                  &.isActive {
                    background-color: var(--layer-selected-01);
                    &::before {
                      @include size(2px, 100%);
                      content: '';
                      display: block;
                      background-color: #F1C21B;
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                  }
                  &.disabled {
                    cursor: default;
                    p {
                      color: var(--text-disabled);
                    }
                  }
                  p {
                    @include string(14px, 18px, normal, 600, left, var(--text-primary));
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@container main-bg (max-width: 768px) {
  .re-320 {
    height: auto !important;
  }
}

.bg-wrap {
  position: relative;
  z-index: 1;
  &.flex-c {
    @include flex;
  }
  &.re-320 {
    height: 320px;
    @include breakpoint(mobile) {
      height: auto;
    }
  }
  &.re-193 {
    height: rem(193);
  }
  &.layer-01::after {
    background-color: var(--layer-01);
  }
  &.layer-02::after {
    background: #080B12;
  }
  &::after {
    display: block;
    content: '';
    position: absolute;
    left: -2000px;
    right: -2000px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
}

// 임시 이미지 처리
.tempImage-wrapper {
  @include flex;
  width: 100%;
  min-height: 150px;
  background-color: var(--layer-01);
}

.tempImage {
  @include flex;
  &::before {
    @include same(24px);
    background: url($G-path-icon + 'tempImage.svg') $G-img-contain;
    content: '';
    display: block;
    @include breakpoint(mobile) {
      @include same(16px);
    }
  }
}

.error-wrapper {
  @include flex;
  min-height: 100%;
  @include breakpoint(mobile) {
    height: 100dvh;
  }

  .pageNotFound-wrapper {
    @include flex;
    @include breakpoint(mobile) {
      padding: 0 16px;
    }
    .pageNotFound-inner {
      @include flex;
      margin-bottom: rem(32);
      img {
        @include same(rem(70));
      }
      p {
        @include string(rem(24), rem(32), normal, 500, center, var(--text-primary));
        margin: 8px 0;
        @include breakpoint(mobile) {
          margin: 6px 0 4px;
        }
      }
      span {
        @include string(14px, 18px, 0.16px, 400, center, var(--text-primary));
        @include breakpoint(mobile) {
          @include string(12px, 16px, 0.12px, 400, center, var(--text-primary));
        }
      }
    }
  }
}

.loading-wrapper {
  @include flex;
  @include same(100%);
  position: fixed;
  z-index: 1000;
  background-color: #000;

  &__inner {
    @include flex;
    img {
      @include same(160px);
    }
  }
}