#main-banner-slider-wrapper {
  @include flex(space-between, center, row);
  gap: 24px;
  position: relative;
  padding: 60px 0;
  @include breakpoint(mobile) {
    padding: 30px 0;
    // display: block;
    // margin-top: 40px;
  }

  .back {
    width: 100%;
    height: 100%;
    position: absolute;
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: -2000px;
      right: -2000px;
      top: 0;
      bottom: 0;
      z-index: 0;
      background-color: var(--field-01);
      opacity: 0.6;
    }
  }

  .btn-next, .btn-prev {
    @include flex;
    @include same(24px);
    background-color: var(--layer-01);
    border-radius: 4px;
    transition: background-color 0.25s ease;
    &:hover {
      background-color: var(--layer-hover-01);
    }
    &::before {
      @include size(5px, 10px);
      content: '';
      display: block;
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .btn-next {
    &::before {
      background: url($G-path-icon + 'arrow_right.svg') $G-img-contain;
    }
  }
  .btn-prev {
    &::before {
      background: url($G-path-icon + 'arrow_left.svg') $G-img-contain;
    }
  }
  .main-banner-slider-inner {
    position: relative;
    // max-width: rem(456);
    // max-height: rem(256);
    // aspect-ratio: 456/256;
    width: 100%;
    // height: 0;
    // aspect-ratio: 16/9;
    padding-bottom: 25%;
    @include breakpoint(mobile) {
      padding: 0;
      height: 100%;
      max-width: inherit;
      max-height: inherit;
      position: static;
    }
    .main-banner-slider-component {
      &.swiper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        // max-width: rem(456);
        // max-height: rem(256);
        // aspect-ratio: 456/256;
        // border-radius: var(--box-radius);
        @include breakpoint(mobile) {
          position: relative;
          // max-width: inherit;
          // max-height: inherit;
        }

        .swiper-wrapper {
          .swiper-slide {
            border-radius: var(--box-radius);
            aspect-ratio: 16/9;
            overflow: hidden;
            &.isLink {
              cursor: pointer;
            }
            img {
              width: 100%;
              // height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.slider-component {
  &.swiper {
    display: flex;
    flex-direction: column-reverse;
    &:not(:last-of-type) {
      margin-bottom: rem(48);
      @include breakpoint(mobile) {
        margin-bottom: 40px;
      }
    }
  }
  &__header {
    @include flex(space-between, center, row);
    margin-bottom: 16px;
    @include breakpoint(mobile) {
      margin-bottom: 12px;
    }
    .slider-title {
      @include flex(flex-start, center, row);
      gap: 12px;
      .title-info {
        display: grid;
        grid-template-columns: 20px auto;
        gap: 8px;
        align-items: center;
        p {
          @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
          text-transform: capitalize;
          @include breakpoint(mobile) {
            @include string(16px, 20px, normal, 500, left, var(--text-primary));
          }
        }
        .icon-set {
          @include same(20px);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          @each $icon in casino, slot, news, guide {
          &.#{$icon} {
              background-image: url($G-path-icon + '#{$icon}.svg');
            }
          }
        }
      }
      .view-all {
        @include string(14px, 18px, 0.16px, 400, left, #FFB800);
        transform: translateY(1.25px);
        @include breakpoint(mobile) {
          transform: none;
        }
      }
    }
    .slider-btn {
      @include flex(flex-end, center, row);
      gap: 8px;
      .btn-next, .btn-prev {
        &.swiper-button-disabled {
          opacity: 0.5;
          cursor: default;
          transition: none;
          pointer-events: none;
        }
        @include flex;
        @include same(24px);
        background-color: var(--layer-01);
        border-radius: 4px;
        transition: background-color 0.25s ease;
        &:hover {
          background-color: var(--layer-hover-01);
        }
        &::before {
          @include size(5px, 10px);
          content: '';
          display: block;
        }
      }
      .btn-next {
        &::before {
          background: url($G-path-icon + 'arrow_right.svg') $G-img-contain;
        }
      }
      .btn-prev {
        &::before {
          background: url($G-path-icon + 'arrow_left.svg') $G-img-contain;
        }
      }
    }
  }
  .swiper-wrapper {
    .swiper-slide {
      cursor: pointer;
      aspect-ratio: 1/1;
      border-radius: var(--box-radius);
      .MuiSkeleton-root {
        height: 100%;
        border-radius: var(--box-radius);
      }
      .tempImage-wrapper {
        border-radius: var(--box-radius);
      }
    }
  }
}