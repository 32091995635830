
@container main-inner (max-width: 768px) {
  .sns-link-wrapper {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    gap: 32px !important;
    padding: 66px 0 !important;
    &__title {
      font-size: 16px !important;
      font-weight: 20px !important;
    }
    .sns-link-inner {
      gap: 16px !important;
      width: 100% !important;
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      .sns-btn {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
  .policy-viewer {
    .policy-viewer__body {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      .MuiButton-root.base-btn {
        padding: 0 5px;
        overflow: hidden;
        p {
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          word-wrap: normal;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .licenses-viewer {
    .licenses-viewer__symbol {
      gap: 16px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .box-img {
        min-height: 137px;
        .licenses {
          &.licenses01 {
            width: 114px;
          }
          &.licenses02 {
            width: 100px;
          }
        }
      }
    }
  }
}
.home-wrapper {
  container: main-bg / inline-size;
  .home-inner {
    container: main-inner / inline-size;
    padding: rem(64) 0 0;
    @include breakpoint(mobile) {
      padding: 40px 0 0;
    }
    .noti-list-wrap {
      .link-list-component {
        margin-top: rem(52);
        @include breakpoint(mobile) {
          margin-top: 40px;
        }
        &__header {
          @include flex(space-between, center, row);
          margin-bottom: 16px;
          @include breakpoint(mobile) {
            margin-bottom: 12px;
          }
          .title {
            @include flex(flex-start, center, row);
            gap: 12px;
            .title-info {
              display: grid;
              grid-template-columns: 20px auto;
              gap: 8px;
              align-items: center;
              p {
                @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
                @include breakpoint(mobile) {
                  @include string(16px, 20px, normal, 500, left, var(--text-primary));
                }
              }
              .icon-set {
                @include same(20px);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                @each $icon in casino, slot, news, guide {
                &.#{$icon} {
                    background-image: url($G-path-icon + '#{$icon}.svg');
                  }
                }
              }
            }
            .view-all {
              @include string(14px, 18px, 0.16px, 400, left, #FFB800);
              transform: translateY(1.25px);
              @include breakpoint(mobile) {
                transform: none;
              }
            }
          }
        }
        &__body {
          display: grid;
          grid-auto-rows: auto;
          margin-top: 20px;

          li {
            @include flex(flex-start, flex-start);
            padding: 20px 0;
            border-bottom: 1px solid #393939;
            overflow: hidden;
            &:first-of-type {
              border-top: 1px solid #393939;
            }
            .title {
              display: grid;
              grid-template-columns: auto 16px;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              margin-bottom: 8px;
              max-width: 80%;
              cursor: pointer;
              @include breakpoint(mobile) {
                max-width: 95%;
                margin-bottom: 3px;
              }
              &::after {
                @include same(16px);
                background: url($G-path-icon + 'link.svg') $G-img-contain;
                content: '';
                display: block;
              }
              p {
                @include string(16px, 20px, normal, 500, left, var(--text-primary));
                overflow: hidden;
                word-break: break-all;
                white-space: nowrap;
                word-wrap: normal;
                text-overflow: ellipsis;
              }
            }
            span {
              @include string(12px, 16px, 0.32px, 400, left, var(--text-helper));
            }
          }
        }
      }
    }
    .sns-link-wrapper {
      @include flex(space-between, center, row);
      gap: rem(24);
      padding: rem(130) 0 rem(71);
      @include breakpoint(mobile) {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 32px;
        padding: 66px 0;
      }
      &__title {
        @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
        @include breakpoint(mobile) {
          @include string(16px, 20px, normal, 600, left, var(--text-primary));
        }
      }
      .sns-link-inner {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px 24px;
        align-items: center;
        @include breakpoint(tablet) {
          grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
          width: 100%;
        }
        @include breakpoint(mobile) {
          gap: 16px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr) !important;
        }
        .sns-btn {
          @include string(14px, 18px, normal, 500, right, var(--text-primary));
          display: grid;
          width: rem(168);
          grid-template-columns: 24px auto;
          justify-content: space-between;
          padding: 0 rem(36) 0 rem(17);
          height: rem(48);
          border-radius: var(--box-radius);
          text-transform: none;
          border: 1px solid var(--border-inverse);
          @include breakpoint(tablet) {
            width: 100%;
            max-width: 100% !important;
            padding: 0 24px 0 17px;
            height: 40px;
          }
          @include breakpoint(mobile) {
            width: 100% !important;
          }

          &.medium::before {
            @include size(24px, 13.92px);
          }
          &.facebook::before {
            @include size(11px, 21px);
          }
          &.twitter-x::before {
            @include same(20px);
          }
          &.youtube::before {
            @include size(22px, 16px);
          }
          &.discord::before {
            @include size(22px, 16px);
          }
          @each $icon in medium, facebook, twitter-x, youtube, discord {
          &.#{$icon} {
              &::before {
                background: url($G-path-icon + 'sns-button-#{$icon}.svg') $G-img-contain;
                content: '';
                display: block;
              }
            }
          }
        }
      }
    }
    .logo-banner-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
      }
      div {
        @include flex;
        @include string(14px, 18px, normal, 400, left, var(--text-primary));
        @include breakpoint(mobile) {
          &:not(:last-of-type) {
            display: none;
          }
        }
      }
    }
  }
}

@container status-mainbanner (max-width: 768px) {
  .user-status-mainbanner {
    padding: 33px 0;
    display: block !important;
    #main-banner-slider-wrapper {
      // display: block !important;
      // margin-top: 40px !important;
      .btn-next, .btn-prev {
        display: none !important;
      }
      .main-banner-slider-inner {
        padding: 0 !important;
        height: 100% !important;
        max-width: inherit !important;
        max-height: inherit !important;
        position: static !important;
      }
      .main-banner-slider-component {
        position: relative !important;
        max-width: inherit !important;
        max-height: inherit !important;
      }
    }
  }
}

.user-status-mainbanner-wrapper {
  container: status-mainbanner / inline-size;
  .user-status-mainbanner {
    padding: 60px 0 0;
    display: grid;
    grid-auto-rows: auto;
    gap: 32px;
    // display: grid;
    // grid-template-columns: rem(415) minmax(auto, rem(552));
    // justify-content: space-between;
    // align-items: center;
    // gap: 24px;
    // height: 100%;
    @include breakpoint(mobile) {
      // display: block;
      padding: 33px 0 0;
    }
    // 로그인상태
    .logged-in {
      margin-left: 48px;
      @include breakpoint(tablet) {
        margin: 0 0 30px 0;
      }
      &__msg {
        @include string(20px, 28px, normal, 700, left, var(--text-primary));
      }
      .user-grade {
        margin-top: rem(12);
        margin-bottom: rem(24);
        @include breakpoint(mobile) {
          margin-top: 8px;
          margin-bottom: 19px;
        }
        &__header {
          @include flex(space-between, center, row);
          margin-bottom: 6px;
          .user-state-grade {
            @include flex(flex-start, center, row);
            gap: 4px;
            p {
              @include string(16px, 20px, normal, 500, left, var(--text-primary));
            }
            span {
              @include string(16px, 20px, normal, 500, left, #7E7E7E);
            }
          }
          .user-state-value {
            @include string(14px, 18px, normal, 500, left, var(--text-primary));
          }
        }
        &__step {
          @include flex(space-between, center, row);
          margin-top: 8px;
          .row-item {
            @include flex(flex-start, center, row);
            gap: 2px;
            p {
              @include string(14px, 18px, normal, 400, left, var(--text-primary));
            }
            img {
              @include same(24px);
            }
          }
        }
      }
    }
    // 로그아웃상태
    .logged-out {
      margin-left: 48px;
      @include breakpoint(tablet) {
        margin: 0 0 30px 0;
      }
      .desc {
        margin-bottom: rem(28);
        @include breakpoint(mobile) {
          margin-bottom: 24px;
        }
        &__title {
          @include string(rem(32), rem(40), normal, 700, left, var(--text-primary));
          margin-bottom: 4px;
          @include breakpoint(mobile) {
            @include string(24px, 32px, normal, 700, left, var(--text-primary));
            margin-bottom: 8px;
          }
        }
        &__info {
          @include string(rem(16), rem(22), normal, 400, left, var(--text-primary));
          @include breakpoint(mobile) {
            @include string(14px, 18px, 0.16px, 400, left, var(--text-primary));
          }
        }
      }
      .login-wrapper {
        @include flex(flex-start, center, row);
        gap: rem(16);
        @include breakpoint(mobile) {
          gap: 12px;
        }
        .primary-btn {
          @include string(14px, 18px, normal, 700, left, var(--text-inverse));
          padding: 0 24px;
          height: 48px;
          background-color: #FFB800;
          border-radius: var(--box-radius);
          text-transform: none;
          @include breakpoint(mobile) {
            height: 40px;
            font-weight: 600;
            margin-right: 4px;
          }
        }
        .or {
          @include string(12px, normal, normal, 400, left, var(--text-primary));
        }
        .sns-login {
          @include flex(flex-start, center, row);
          gap: 12px;
          .icon-r {
            @include flex;
            @include same(32px);
            padding: 0;
            min-width: inherit;
            @include breakpoint(mobile) {
              @include same(28px);
            }
            @each $icon in login-google, login-facebook, login-whatsapp {
            &.#{$icon} {
                &::after {
                  @include same(100%);
                  background: url($G-path-icon + '#{$icon}.svg') $G-img-contain;
                  content: '';
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
.policy-viewer {
  padding: rem(83) 0 rem(80);
  border-top: 1px solid #393939;
  border-bottom: 1px solid #393939;
  @include breakpoint(mobile) {
    padding: 0 0 66px;
    border: none;
  }
  &__header {
    @include flex(space-between, center, row);
    margin-bottom: 16px;
    @include breakpoint(mobile) {
      margin-bottom: 12px;
    }
    .title {
      display: grid;
      grid-template-columns: 20px auto;
      gap: 8px;
      align-items: center;
      p {
        @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
        @include breakpoint(mobile) {
          @include string(16px, 20px, normal, 500, left, var(--text-primary));
        }
      }
      .icon-set {
        @include same(20px);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        background-image: url($G-path-icon + 'content-header-policies.svg');
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    @include breakpoint(mobile) {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      .MuiButton-root.base-btn {
        padding: 0 5px;
        overflow: hidden;
        p {
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          word-wrap: normal;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.licenses-viewer {
  @include flex(flex-start, flex-start);
  padding: rem(80) 0 rem(150);
  @include breakpoint(mobile) {
    padding: 0 0 97px;
  }
  &__desc {
    display: grid;
    grid-auto-rows: auto;
    gap: 16px;
    .title {
      display: grid;
      grid-template-columns: 20px auto;
      gap: 8px;
      align-items: center;
      p {
        @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
        @include breakpoint(mobile) {
          @include string(16px, 20px, normal, 500, left, var(--text-primary));
        }
      }
      .icon-set {
        @include same(20px);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        background-image: url($G-path-icon + 'content-header-license.svg');
      }
    }
    .info {
      @include string(16px, 20px, normal, 500, left, var(--text-primary));
    }
  }
  &__symbol {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    align-items: center;
    margin-top: 16px;
    @include breakpoint(mobile) {
      gap: 16px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
    .box-img {
      @include flex;
      width: 100%;
      min-height: rem(230);
      border-radius: var(--box-radius);
      background: var(--layer-01);
      @include breakpoint(mobile) {
        min-height: 137px;
      }
      .licenses {
        object-fit: contain;
        &.licenses01 {
          width: rem(190);
          @include breakpoint(mobile) {
            width: 114px;
          }
        }
        &.licenses02 {
          width: rem(168);
          @include breakpoint(mobile) {
            width: 100px;
          }
        }
      }
    }
  }
}

.maintenance-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .maintenance-content {
    max-width: 600px;
    padding: 40px;
    background: var(--bg-secondary);
    border-radius: 8px;
    text-align: center;

    h1 {
      color: var(--text-primary);
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 1.5;
    }

    .maintenance-info {
      color: var(--text-secondary);
      
      p {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}
// .policy-viewer-wrapper {
//   padding: 0 0 rem(155);
//   @include breakpoint(mobile) {
//     padding: 0 0 97px;
//   }
//   .policy-viewer-header {
//     @include flex(space-between, center, row);
//     @include breakpoint(mobile) {
//       display: grid;
//       grid-template-columns: auto 156px;
//       gap: 20px;
//     }
//     .state-title {
//       @include string(rem(20), rem(28), normal, 600, left, var(--text-primary));
//       @include breakpoint(mobile) {
//         @include string(16px, 20px, normal, 600, left, var(--text-primary));
//       }
//     }
//     .MuiInputBase-root.base-select .MuiSelect-select.MuiSelect-select {
//       @include breakpoint(mobile) {
//         width: 100%;
//       }
//     }
//   }
//   .policy-viewer-body {
//     margin-top: 30px;
//     @include breakpoint(mobile) {
//       margin-top: 33px;
//     }

//     &__inner {
//       height: 100%;
//       max-height: auto;
//       position: relative;
//       overflow: hidden;
//       &.closing {
//         max-height: rem(380);
//         @include breakpoint(mobile) {
//           max-height: 500px;
//         }
//         &::after {
//           opacity: 1;
//         }
//       }
//       &::after {
//         @include same(100%);
//         content: '';
//         display: block;
//         background: linear-gradient(180deg, rgba(22, 22, 22, 0.00) 0%, #161616 100%);
//         position: absolute;
//         top: 0;
//         left: 0;
//         z-index: 1;
//         opacity: 0;
//         transition: opacity 0.2s ease;
//       }
//       li {
//         &:not(:last-of-type) {
//           margin-bottom: rem(22);
//           @include breakpoint(mobile) {

//           }
//         }
//         @include string(rem(16), rem(24), normal, 300, left, var(--text-primary));
//         @include breakpoint(mobile) {
//           @include string(14px, 20px, 0.16px, 400, left, var(--text-primary));
//         }
//       }
//     }
//   }
//   .policy-viewer-footer {
//     @include flex;
//     margin: rem(20) 0 0;
//     .more-btn {
//       @include flex(flex-start, center, row);
//       @include string(14px, 18px, normal, 500, right, var(--text-primary));
//       cursor: pointer;
//       &::after {
//         @include same(24px);
//         content: '';
//         display: block;
//         background: url($G-path-icon + 'more.svg') $G-img-contain;
//       }
//       &.open::after {
//         transform: rotate(180deg);
//       }
//     }
//   }
// }

